<template>
	<div class="details">
		<p class="title">
			{{ detailsData.title || '' }}
		</p>
		<div class="detailsMass-item-iter" v-if="detailsData.items && detailsData.items.length > 0">
			<div class="iter clearfix">
				<div
					v-for="(item, index) in detailsData.items"
					:key="index"
					:class="!item.items ? 'otherData clearfix' : item.items ? 'threeData clearfix' : ''"
				>
					<p class="float-l" :style="{ lineHeight: item.height + 'px', height: item.height + 'px', overflow: 'hidden' }">
						{{ item.title }}
					</p>
					<div class="threeData-item float-l clearfix" v-if="item.items && item.items.length > 0">
						<div v-for="(subItem, subIndex) in item.items" :key="subIndex">
							<div v-if="item.title != '报告地区' && item.title != '发生地区'">
								<p style="width: 100%">{{ subItem.value && subItem.value.toString() }}</p>
							</div>
							<div v-else>
								<p
									class="float-l"
									:style="{
										lineHeight: subItem.height + 'px',
										height: subItem.height + 'px',
									}"
								>
									{{ subItem.title }}
								</p>
								<div class="threeData-item-item float-l" v-if="subItem.items && subItem.items.length != 0">
									<div class="clearfix" v-for="(child, childIndex) in subItem.items" :key="childIndex">
										<p
											class="float-l"
											:style="{
												width: '50%',
												lineHeight: child.height + 'px',
												height: child.height + 'px',
											}"
										>
											{{ child.title }}111
										</p>
										<div class="threeData-item-item-item float-l" v-if="child.items && child.items.length != 0">
											<p v-for="(subchild, subchildIndex) in child.items" :key="subchildIndex">{{ subchild.title }}1111</p>
										</div>
										<div class="threeData-item-item-item float-l" v-else>
											<p style="width: 100%">
												{{ child.value && child.value.toString() }}
											</p>
										</div>
									</div>
								</div>
								<div class="threeData-item-item float-l" v-else>
									<p style="width: 100%">{{ subItem.value && subItem.value.toString() }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="nullData" v-else>暂无数据</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			detailsData: {},
		};
	},
	mounted() {
		this.getDetails();
	},
	methods: {
		getDetails() {
			if (!this.$route.query.id) return;
			this.$http
				.get(`/diagnosis-api/v1/PersonForms/${this.$route.query.id}.json`)
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection[0] || {};
						let data = JSON.parse(collection.content);
						if (!data || data.items.length == 0) return;
						this.getHeight(data);
					}
				})
				.catch((e) => {});
		},
		getHeight(data) {
			let newData = JSON.parse(JSON.stringify(data));
			const singleHeight = 48;
			handleItemHeight(newData);
			function handleItemHeight(objItem) {
				if (!objItem) return 0;
				if (!objItem.items || objItem.items.length === 0) {
					objItem.height = singleHeight;
					return singleHeight;
				}
				objItem.height = 0;
				objItem.items.map((item) => {
					let childHeight = handleItemHeight(item);
					objItem.items.height = childHeight;
					objItem.height += childHeight;
				});
				return objItem.height;
			}
			this.detailsData = JSON.parse(JSON.stringify(newData));
			console.log(this.detailsData);
		},
	},
};
</script>

<style lang="scss" scoped>
.details {
	width: 80%;
	padding: 24px 40px;
	background-color: #fff;
	margin: 16px auto;
	.title {
		font-size: 16px;
		color: #333333;
		padding-left: 8px;
		border-left: 3px solid #1db9b1;
		line-height: 16px;
		margin-bottom: 16px;
	}
	.detailsMass-item-iter {
		position: relative;
		border-right: 1px solid rgba(214, 217, 224, 0.8);
		border-bottom: 1px solid rgba(214, 217, 224, 0.8);

		.iter {
			width: calc(100% - 164px);
			p {
				color: #333;
				padding-left: 16px;
				line-height: 48px;
				height: 48px;
				border: 1px solid rgba(214, 217, 224, 0.8);
				width: calc(50% - 165px);
				border-bottom: none;
				border-right: none;
				&:nth-child(2n-1) {
					width: 165px;
					background-color: #f4f6fd;
					border-right: none;
				}
			}
			.otherData {
				width: calc(100% + 164px);
				p {
					width: calc(100% - 165px);
					&:first-child {
						width: 165px;
						background-color: #f4f6fd;
					}
				}
			}
			.threeData {
				width: calc(100% + 164px);
				&-item {
					width: calc(100% - 165px);
					p {
						width: calc(50% - 154px);
						background-color: #fff;
					}
					&-item {
						width: calc(100% - calc(50% - 154px));
						p {
							width: 50%;
							background-color: #fff;
						}
						&-item {
							width: 50%;
							p {
								width: 100%;
								background-color: #fff;
							}
						}
					}
				}
			}
		}
	}
}
</style>